<template>
  <div class="export-list-cdg">
    <b-dropdown
      id="dropdown-export"
      ref="dropdown-export"
      right
      variant="none"
      no-caret
      class="mt-1"
    >
      <template #button-content>
        <font-awesome-icon
          icon="arrow-circle-down"
          title="Liste des fichiers"
          class="icon-down-flesh"
        />

        <div class="loader-export-globale-cdg" v-if="false">
          <Half-circle-spinner
            :animation-duration="1000"
            :size="50"
            :color="'#4d4bac'"
            class="loader"
          />
        </div>
      </template>

      <div class="fichier-option">
        <span class="not-style">Liste de fichier</span>
      </div>
      <hr class="hr-style" />
      <LoadingComponent v-if="getLoadingListFichierExporteCdg" />
      <LoadingComponent v-if="getLoadingListFichierExporteCdg" />
      <div class="list-files" v-else>
        <div
          class="dropdown-item-list"
          v-for="file in getListFichierExporteCdg"
          :key="file.id"
        >
          <div class="content-item">
            <div class="icon-type">
              <font-awesome-icon
                class="file-icon"
                :icon="file.file_type == 'xlsx' ? 'file-excel' : 'folder'"
                :class="{ 'icon-excel': file.file_type == 'xlsx' }"
              />
            </div>
            <div class="body-item">
              <div>
                <span>{{ file.file_name }} </span>
              </div>
              <div class="time">
                <span
                  class="Plus"
                  @click.prevent.stop="handleDownloadFile(file)"
                >
                  <Hollow-dots-spinner
                    v-if="file.loaderExport"
                    :animation-duration="1000"
                    :size="50"
                    :color="'#4d4bac'"
                    class="loader loaderExportFileInList"
                  />
                  <span v-else>Télecharger</span>
                </span>
                <span class="expire-text">{{ computedValidation(file) }}</span>
                <span>{{ file.created_at }} </span>
              </div>
            </div>
            <div
              class="icon-close"
              @click.prevent.stop="handleDeleteFile(file)"
            >
              <div
                class="chargement chargement-loading-icon"
                v-if="file.loaderDelet"
              >
                <div class="spinner-border" role="status">
                  <span class="sr-only">Loading...</span>
                </div>
              </div>
              <font-awesome-icon :icon="'times'" v-else />
            </div>
          </div>
        </div>
      </div>
      <div
        class="fichier-vide"
        v-if="
          getListFichierExporteCdg.length == 0 &&
            !getLoadingListFichierExporteCdg
        "
      >
        aucun fichier
      </div>
    </b-dropdown>
  </div>
</template>

<script>
import LoadingComponent from '@/views/component/LoadingComponent';
import { mapGetters, mapActions } from 'vuex';
import moment from 'moment';
export default {
  computed: {
    ...mapGetters([
      'getListFichierExporteCdg',
      'getLoadingListFichierExporteCdg',
      'getErrorListFichierExporteCdg'
    ]),
    computedValidation() {
      return function(file) {
        let date1 = moment(file.created_at, 'DD-MM-YYYY');
        let date2 = moment(
          moment(new Date()).format('DD-MM-YYYY'),
          'DD-MM-YYYY'
        );
        let daysDiff = date2.diff(date1, 'days');

        let expire = 15 - daysDiff;
        if (daysDiff >= 13 && daysDiff < 15)
          return 'expire dans ' + expire + ' j';
        else if (daysDiff == 15) return "expire aujourd'hui";
        return '';
      };
    }
  },
  methods: {
    ...mapActions([
      'fecthListFichierExporteCdg',
      'deleteFileInListExporte',
      'exportFileInListExporte'
    ]),
    async handleDeleteFile(file) {
      file.loaderDelet = true;
      await this.deleteFileInListExporte(file);
      file.loaderDelet = false;
    },
    async handleDownloadFile(file) {
      file.loaderExport = true;
      await this.exportFileInListExporte(file);
      file.loaderExport = false;
    }
  },
  mounted() {
    this.fecthListFichierExporteCdg();
  },
  components: {
    LoadingComponent
  }
};
</script>

<style lang="scss">
.export-list-cdg {
  .dropdown-menu {
    padding: 5px;
    width: 277px;
    background-color: #ffffff;
    overflow-y: hidden;
    max-height: 364px;
    border: none;
    border-radius: 16px;
    box-shadow: 0 0 5px #ced0d4;
  }
  .fichier-option {
    display: flex;
    margin: 4px 10px 5px 10px;
    justify-content: start;
    .not-style {
      color: #5d5d5d;
      font-family: 'Montserrat', sans-serif;
      font-size: 14px;
      font-weight: 600;
    }
  }
  .hr-style {
    color: #d9d9d9;
    margin: 2px;
  }
  .fichier-vide {
    font-size: 14px;
    align-items: center;
    text-align: center;
    margin: 15px;
    font-weight: 400;
    color: #5d5d5d;
  }
  .list-files {
    overflow-y: auto;
    max-height: 323px;
  }
  .list-files::-webkit-scrollbar {
    width: 10px;
  }

  .list-files::-webkit-scrollbar {
    width: 7px;
    height: 7px;
  }
  .list-files::-webkit-scrollbar-track {
    box-shadow: inset 0 0 5px #ffffff;
    border-radius: 10px;
  }
  .list-files::-webkit-scrollbar-thumb {
    background: #8d8cb7;
    border-radius: 7px;
  }
  .dropdown-item-list {
    padding: 0.5rem;
    margin-left: 0;
    font-size: 0.8rem;
    color: #000;
    border: none;
    margin-bottom: 5px;
    box-shadow: 0px 1px 3px 0px #dadada;
    border-radius: 5px;
    .content-item {
      display: flex;
      justify-content: space-between;
      cursor: pointer;
      .icon-type {
        min-width: 30px;
        max-width: 30px;
        font-size: 24px;
        padding-top: 8px;
      }
      .icon-close {
        width: 19px;
        height: 19px;
        font-size: 10px;
        text-align: center;
        color: #5f6368;
        &:hover {
          background: #dee0e1;
          border-radius: 50px;
        }
      }
      .body-item {
        display: inline;
        font-size: 11px;
        width: 77%;
      }
    }
    .time {
      font-size: 80%;
      font-weight: 600;
      justify-content: space-between;
      display: flex;
    }
    .active {
      color: #8d8cb7;
    }
  }
  #dropdown-export > button {
    background-color: #4d4bac !important;
    box-shadow: none !important;
    margin-top: 2px;
    padding: 6.5px;
    font-size: 11px;
    font-weight: 600;
    color: #ffffff;
    border-radius: 25px;
    padding: 6px 8px;
    border: none;
    box-shadow: 5px 6px 8px #9799d67a;
  }

  .loader-export-globale-cdg {
    position: absolute;
    height: 93%;
    top: -6px;
    z-index: 10;
    .loader {
      margin-left: -34%;
      width: 43px !important;
      height: 42px !important;
    }
  }
  .icon-excel {
    color: #96c296 !important;
  }
  .file-icon {
    color: #bc7171;
  }
  .Plus {
    color: #4d4bac;
    font-size: 9px;
    text-align: end;
    font-weight: 600;
    &:focus,
    &:hover {
      color: #080758;
    }
  }
  .expire-text {
    color: red;
  }
  .chargement-loading-icon {
    margin-left: 0px !important;
    .spinner-border {
      width: 0.7rem !important;
      height: 0.7rem !important;
    }
  }
  .icon-down-flesh {
    font-size: 13px;
  }
}
.loaderExportFileInList {
  .dot {
    width: 10px !important;
    height: 10px !important;
    margin: 0px 3.5px !important;
  }
}
</style>
