<template>
  <div class="timeline-item d-flex">
    <div class="image"></div>
    <div class="content">
      <div class="title"></div>
      <div class="short-title"></div>
    </div>
  </div>
</template>

<script>
export default {
  name: 'LoadingComponent'
};
</script>
<style lang="scss" scoped>
.timeline-item .image {
  width: 50px;
  height: 50px;
  border-radius: 47px;
  animation: loader 3s infinite ease-in-out;
  -webkit-animation: loader 3s infinite ease-in-out;
}

.timeline-item .content .title {
  height: 12px;
  margin: 10px;
  width: 190px;
  animation: loader 3s infinite ease-in-out;
  -webkit-animation: loader 3s infinite ease-in-out;
}

.timeline-item .content .short-title {
  height: 12px;
  margin: 10px;
  width: 190px;
  animation: loader 3s infinite ease-in-out;
  -webkit-animation: loader 3s infinite ease-in-out;
}

@keyframes loader {
  0% {
    background-color: rgba(165, 165, 165, 0.1);
  }
  50% {
    background-color: rgba(165, 165, 165, 0.3);
  }
  100% {
    background-color: rgba(165, 165, 165, 0.1);
  }
}

@-webkit-keyframes loader {
  0% {
    background-color: rgba(165, 165, 165, 0.1);
  }
  50% {
    background-color: rgba(165, 165, 165, 0.3);
  }
  100% {
    background-color: rgba(165, 165, 165, 0.1);
  }
}
</style>
